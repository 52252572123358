/* logo */

.logo {
  height: 60px;
}

/* header trigger icon */

.anticon-menu-fold,
.anticon-menu-unfold {
  margin-left: 20px;
  font-size: 20px;
}

/* login */
.page-normal-login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 370px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 50px 40px;
  border-radius: 6px;
}
.login-form {
  max-width: 370px;
}
.login-form-forgot {
  padding-left: 0px;
  padding-top: 0px;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}

.login-wrapper-inner{
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 14vh;
}

.login-wrapper-inner p{
  margin-top: 12px;
  color: #9d9d9d;
  font-size: 15px;
}
/* layout */

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout-wrapper {
  height: 100vh;
}

.site-layout .site-layout-background {
  background: #fff;
  overflow-y: auto;
}

/* form styles */

.row-space-last-name {
  bottom: 65px;
}
.row-space-gender {
  bottom: 65px;
}
.row-space-material-status {
  bottom: 65px;
  margin-bottom: -55px;
}

/* image upload */

.image-upload {
  height: 125px;
  width: 125px;
  background-color: #dfe5eb;
  overflow: hidden;
}
.image-upload img {
  width: 125px;
}

.image-upload
  .ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-eye {
  display: none;
}

.image-upload .ant-upload.ant-upload-select-picture-card,
.ant-upload-list-picture-card-container {
  width: 125px;
  height: 125px;
}

/* checkin checkout screen */
.checkin-checkout .ant-btn {
  height: 115px;
  width: 120px;
}
.checkin-checkout .ant-btn-primary:hover, .ant-btn-primary:focus {
  border-color: #ffffff !important;
  background-color: #be4141 !important;
}

.checkin-checkout .ant-btn-primary {
  border-color: #ffffff !important;
  background: #e05d5d;
}

.inner-secondry {
  font-size: 12px;
}
.inner-primary {
  font-weight: 500;
  font-size: 12px;
  margin-top: 0px;
  padding: 8px 10px;
}

/* team edit form */
.team-edit-form {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.team-edit-form-space {
  margin-right: 20px;
  margin-bottom: 20px;
}

.ant-timeline-item-tail {
  border-left: 2px solid #d9d9d9;
}

.first-capital {
  text-transform: capitalize !important;
}

.ant-btn-primary {
  border-color: #e05d5d;
  background: #e05d5d;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  border-color: #be4141;
  background: #be4141;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e05d5d;
}

.ant-switch-checked {
  background-color: #e05d5d;
}

.forget-pass-form  .ant-row{
    display: block;
}

.inner-count-leave-card{
  display: flex;
  align-items: center;
}

.inner-count-leave-card span{
  font-size: 16px;
  background-color: #f5f5f5;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 10px;
}

.inner-count-leave-card-content p{
  margin-bottom: 0px;
  margin-left: 20px;
}
.leave-card-content-title{
  font-size: 12px;
}
.leave-card-content-count{
  font-size: 20px;
  font-weight: 800;
  line-height: 1;
}

.fingerprin-wrapper img{
  width: 80%;
}

.loader-fingerprint{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
